import { POST, GET } from "@/utils/util";
var HotTopicApi = {
  // 获取热点话题列表
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/hottopic/manage/list", params);
  },
  // 删除热点话题{id:xxx}
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hottopic/manage/delete", params);
  },
  // 热点话题排序
  sort: function sort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hottopic/manage/sort", params);
  },
  // 新增热点话题
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/hottopic/manage/add", params);
  },
  // 模糊匹配话题名称
  querytopic: function querytopic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/hottopic/manage/querytopic", params);
  }
};
export default HotTopicApi;