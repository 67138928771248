import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import Mixin from "@/utils/mixin";
import { mapGetters } from "vuex";
import Form from "./form";
export default {
  name: "dcBannerSortTable",
  components: {
    Form: Form
  },
  // 定义属性
  props: {
    columns: {
      type: Array
    },
    dataSource: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    // 是否开启排序 false为开启 true为不开启
    isopensort: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    isshowDeleteVisible: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      tipsDesc: "",
      isDeleteVisible: false,
      previewImage: "",
      previewVisible: false,
      modalTitle: "编辑",
      modalVisible: false,
      editId: "",
      selectId: {},
      isDelete: false,
      action: "",
      actionLoading: false
    };
  },
  mixins: [Mixin],
  computed: _objectSpread(_objectSpread({}, mapGetters("dcModule", ["moduleListObj", "newsTypeListObj"])), {}, {
    splitImg: function splitImg() {
      return function (imgurl) {
        if (imgurl.indexOf(",") !== -1) {
          return imgurl.split(",")[0];
        } else if (imgurl.indexOf("|") !== -1) {
          return imgurl.split("|")[0];
        } else {
          return imgurl;
        }
      };
    }
  }),
  // 方法集合
  methods: {
    // 编辑表单
    editorClick: function editorClick(item) {
      this.modalVisible = true;
      this.editId = item.id;
    },
    // 取消删除
    handleCancle: function handleCancle() {
      this.isDeleteVisible = false;
    },
    // 提示框确认操作
    handleSubmit: function handleSubmit() {
      if (this.action == "delete") {
        this.$emit("action", this.action, this.selectId);
        this.isDeleteVisible = false;
      } else if (this.action == "setting") {
        this.$emit("action", this.action, this.selectId);
        this.isDeleteVisible = false;
      }
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.isDelete = false;
      if (action == "delete") {
        this.isDelete = true;
        if (this.isshowDeleteVisible) {
          this.tipsDesc = "确定删除吗？";
          this.isDeleteVisible = true;
          this.selectId = record;
        } else {
          //删除当前行商品
          this.$emit("action", this.action, this.selectId);
        }
      } else if (action == "setting") {
        this.tipsDesc = "确认生效该类型？";
        this.isDeleteVisible = true;
        this.selectId = record;
      } else if (action == "edit") {
        this.$emit("action", this.action, record);
      }
    },
    // 拖动排序
    customRow: function customRow(record, index) {
      var _this = this;
      if (this.isopensort) {
        return;
      }
      return {
        // FIXME: draggable： true //不生效还不晓得是什么原因，先使用鼠标移入事件设置目标行的draggable属性
        attrs: {
          draggable: true
        },
        style: {
          cursor: "pointer"
        },
        on: {
          // 鼠标移入
          mouseenter: function mouseenter(event) {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // 开始拖拽
          dragstart: function dragstart(event) {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            _this.sourceObj = record;
          },
          // 拖动元素经过的元素
          dragover: function dragover(event) {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: function drop(event) {
            var _this$dataSource;
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            _this.targetObj = record;
            _this.dataSource.forEach(function (item, index) {
              if (item.id === _this.sourceObj.id) {
                _this.sourceIndex = index;
              }
              if (item.id === _this.targetObj.id) {
                _this.targetIndex = index;
              }
            });
            (_this$dataSource = _this.dataSource).splice.apply(_this$dataSource, [_this.targetIndex, 1].concat(_toConsumableArray(_this.dataSource.splice(_this.sourceIndex, 1, _this.dataSource[_this.targetIndex]))));
            _this.$emit("sortdataSource", _this.dataSource);
            // console.log(this.sourceObj, this.targetObj, "target");
          }
        }
      };
    },
    handleModalCancel: function handleModalCancel() {
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.$emit("refresh");
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    }
  },
  created: function created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted: function mounted() {}
};