var columns = [{
  title: '热点ID',
  dataIndex: 'id',
  key: 'id',
  width: '18%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '热点名称',
  dataIndex: 'topicname',
  key: 'topicname',
  width: '30%',
  scopedSlots: {
    customRender: 'topicname'
  }
}, {
  title: '绑定话题',
  dataIndex: 'name',
  key: 'name',
  width: '25%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '热点排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '12%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '15%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '100px',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };