import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import HotTopicApi from "@/api/dc/hotTopic";
import SelectPage from "@/components/SelectPage.vue";
export default {
  name: "dcHotTopicForm",
  components: {
    SelectPage: SelectPage
  },
  props: {
    editId: [String, Number],
    type: [String, Number]
  },
  data: function data() {
    return {
      fetching: false,
      disabledLoading: false,
      item: {},
      topics: [],
      page: 1,
      size: 10,
      total: 0,
      timer: null
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return false;
    },
    num: function num() {
      var _this = this;
      var record = this.topics.find(function (it) {
        return it.id == _this.item.id;
      });
      return (record === null || record === void 0 ? void 0 : record.num) || 0;
    }
  },
  //   watch: {
  //     'item.id': (val) => {

  //     }
  //   },

  methods: {
    // 话题列表
    getTopicList: function getTopicList() {
      var _this2 = this;
      var search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      if (!search) return;
      this.fetching = true;
      this.topics = [];
      this.total = 0;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        HotTopicApi.querytopic({
          page: _this2.page,
          size: _this2.size,
          name: search
        }).then(function (res) {
          var _ref = res || {},
            _ref$records = _ref.records,
            records = _ref$records === void 0 ? [] : _ref$records,
            total = _ref.total;
          _this2.topics = records;
          _this2.total = total;
        }).catch(function () {}).finally(function () {
          _this2.fetching = false;
        });
      }, 300);
    },
    cancle: function cancle() {
      this.$emit("handleAddCancle");
    },
    submit: function submit() {
      var _this3 = this;
      if (this.disabledLoading) return;
      this.$refs.dcHotTopicForm.validate(function (valid) {
        if (valid) {
          _this3.disabledLoading = true;
          var params = _objectSpread({}, _this3.item);
          HotTopicApi.add(_objectSpread({}, params)).then(function () {
            _this3.toast("操作成功", "success");
            _this3.$emit("handleAddSubmit");
          }).catch(function () {
            _this3.disabledLoading = false;
          }).finally(function () {
            _this3.disabledLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    revalidateField: function revalidateField(field) {
      var _this4 = this;
      setTimeout(function () {
        _this4.$refs.dcHotTopicForm.validateField(field);
      }, 100);
    }
  }
};