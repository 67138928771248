var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcHotTopicForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.item
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "热点名称",
      prop: "topicname",
      rules: [{
        required: true,
        message: "请输入热点名称"
      }, {
        type: "string",
        max: 200,
        message: "最多200个字符"
      }]
    }
  }, [_c("a-input", {
    attrs: {
      type: "text",
      maxLength: 200,
      placeholder: "最多200个字符"
    },
    model: {
      value: _vm.item.topicname,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "topicname", $$v);
      },
      expression: "item.topicname"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "绑定话题",
      prop: "id",
      rules: {
        required: true,
        message: "请选择话题"
      }
    }
  }, [_c("SelectPage", {
    attrs: {
      searchMode: "remote",
      total: _vm.total,
      page: _vm.page,
      list: _vm.topics,
      loading: _vm.fetching,
      placeholder: "请输入关键字进行搜索"
    },
    on: {
      search: _vm.getTopicList,
      changePage: function changePage(p) {
        return _vm.page = p;
      }
    },
    model: {
      value: _vm.item.id,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "id", $$v);
      },
      expression: "item.id"
    }
  }), _vm.item.id ? _c("p", [_vm._v(" 检测结果：该话题下存在"), _c("strong", [_vm._v(_vm._s(_vm.num))]), _vm._v("个关联视频 ")]) : _vm._e()], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };